<template>
  <div class="card" v-if="selectedTopic">
    <!-- ToDo -->
    <!-- 
        add 
        rows="rowsPagValue"
        
        :sort-field="sortFieldValue"
        :sort-order="sortOrderValue"
        @sort="onSort($event)" // create onSortMethod
        @page="onSort($event)"
     -->
    <DataTable
      :value="showIsCompanyFromTopic()" 
      :loading="loading" 
      responsiveLayout="scroll" 
      showGridlines 
      :paginator="true"

      :rows="rowsPagValue"
      :rowsPerPageOptions="[ 25,50, 100]"
      :rowHover="true"
      removableSort

      @row-click="open"

      dataKey="id"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      v-model:filters="filters"
      filterDisplay="menu"
      :sort-field="sortFieldValue"
      :sort-order="sortOrderValue"
      @sort="onSort($event)"
      @page="onSort($event)"
    >
     <!-- HEADER -->
      <template #header>
        <div style="display: flex; justify-content: space-between;">
          <div>
            <h5 v-if="showAllCompanies" class="mb-2 md:m-0 p-as-md-center">Companies</h5>
            <h5 v-else class="mb-2 md:m-0 p-as-md-center">Companies: {{selectedTopic.name}}</h5>
          </div>
          <div class="mb-2 md:m-0 p-as-md-center" ><label >show <strong>All Companies</strong> </label><InputSwitch v-model="showAllCompanies" style="margin-left: 0.7rem;" @click="changeShowAllCompanies()" /> </div>
        </div>
        <div class="table-header row gy-3 d-flex flex-row justify-content-between">
          <div class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start">
            <!-- CreateCompany -->
            <Button
              type="button"
              icon="pi pi-plus"
              label="Add"
              class="p-button-outlined"
              @click="goToCreateCompany()"
            />
          </div>
          <div class="col-sm-12 col-md-8">
            <div class="d-flex flex-row justify-content-md-end">
              <Button 
                type="button" 
                icon="pi pi-filter-slash" 
                label="Clear" 
                class="p-button-outlined me-4"
                @click="clearFilter()" 
              />

              <Dropdown
                v-model="filterSupplyChain"
                :options="supplyChain"
                optionLabel="name"
                optionValue="name"
                placeholder="Filter SupplyChain "
                :showClear="true"
                class="me-4"
                style="width:25%;"
              >
              </Dropdown>

              <Dropdown
                v-model="filterCompanyTopicProfile"
                :options="companyTopicProfile"
                optionLabel="name"
                optionValue="name"
                placeholder="Filter ProfileType"
                :showClear="true"
                class="me-4"
                style="width:25%;"
              >
              </Dropdown>

              <span class="p-input-icon-left" style="position: relative;">
                <i class="pi pi-search" style="position: absolute; left: 5%; top:50%" />
                <InputText 
                  v-model="filters['global'].value" 
                  placeholder="Keyword Search" 
                  class="keywordSearch" 
                />
              </span>
            </div>
            </div>
        </div>

      </template>

      <!-- Name  Columns -->
      <Column 
        v-for="col of columns" 
        :field="col.field" 
        :header="col.header" 
        :key="col.field" 
        :sortable="true"
      >
      </Column>
      
      <!-- DocumentsLength -->
      <Column
        :header="'Docs'"
        :key="'Document count'"
        :sortable="true"
        bodyStyle="text-align:right"
      >
        <template #body="slotProps">
          {{ slotProps.data.totalDocuments }}
        </template>
      </Column>

      <!-- ProgramLength -->
      <Column
        :header="'Prog'"
        :key="'Program Count '"
        :sortable="true"
        :body-style="'text-align:right'"
      >
      <template #body="slotProps">
        {{ slotProps.data.totalPrograms  }}      
      </template>
      </Column>

      <!-- DateCreated -->
      <Column
        :field="'dateCreated'"
        :header="'Created'"
        :key="'dateCreated'"
        :sortable="true"
        bodyStyle="text-align:center"
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.dateCreated)  }}
        </template>
      </Column>

      <!-- DateUpdated -->
      <Column
        :field="'dateUpdated'"
        :header="'Updated'"
        :key="'dateUpdated'"
        :sortable="true"
        bodyStyle="text-align:center"
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.dateUpdated)  }}
        </template>
      </Column>

      <!-- SupplyChain  -->
      <Column
        :field="'supplyChain'"
        :header="'SupplyChain'"
        :key="'SupplyChain'"
        :sortable="true"
        :body-style="'text-align:right'"
        style="width: 5rem;"
      >
        <template #body="slotProps">
          <div v-if="slotProps.data.supplyChain">
            {{ slotProps.data.supplyChain  }}      
          </div>
          <div v-else style="color: #8B0000; background-color: #FFCCCB;">
            not selected
          </div>
        </template>
      </Column>

      <!-- ProfileType  -->
      <Column
        :field="'companyTopicProfile'"
        :header="'ProfileType'"
        :key="'ProfileType'"
        :sortable="true"
        :body-style="'text-align:right'"
        style="width: 5rem;"
      >
        <template #body="slotProps">
          <div v-if="slotProps.data.companyTopicProfile">
            {{ slotProps.data.companyTopicProfile  }}      
          </div>
          <div v-else style="color: #8B0000; background-color: #FFCCCB;">
            not selected
          </div>

        </template>
      </Column>

      <!-- Preview Company -->
      <Column
        style="min-width: 5rem"
      >
        <template #body="slotProps">
          <div style="display: flex;">
              <div>
                <div v-if="slotProps.data.description">
                  <Button
                    icon="pi pi-eye"
                    class="p-button-rounded p-button-success mx-1"
                    @click="openPreviewDescriptionDialog(slotProps.data)"
                    @mouseenter="hoverPreviewDescriptionDialogIn(slotProps.data)"
                    @mouseleave="hoverPreviewDescriptionDialogOut"
                  >
                    <font-awesome-icon icon="fa-light fa-square-poll-horizontal" />
                  </Button>
                </div>
                <div v-else>
                  <Button
                    icon="pi pi-eye"
                    class="p-button-rounded p-button-success mx-1"
                    :disabled="true"
                  >
                    <font-awesome-icon icon="fa-light fa-square-poll-horizontal" />
                  </Button>
                </div>
              </div>

              <div>
                <div v-if="slotProps.data.topicDescription">
                  <Button
                    icon="pi pi-file"
                    class="p-button-rounded p-button-success mx-1"
                    @click="openPreviewTopicDescriptionDialig(slotProps.data)"
                    @mouseenter="hoverPreviewTopicDescriptionDialogIn(slotProps.data)"
                    @mouseleave="hoverPreviewTopicDescriptionDialogOut"
                  >
                    <font-awesome-icon icon="fa-light fa-square-t" />
                  </Button>
                </div>
                <div v-else>
                  <Button
                    icon="pi pi-file"
                    class="p-button-rounded p-button-success mx-1"
                    :disabled="true"
                  >
                    <font-awesome-icon icon="fa-light fa-square-t" />
                  </Button>
                </div>
              </div>
              
              <div>
                <div v-if="slotProps.data.companyAnalysesItems.length> 0">
                  <Button
                    icon="pi pi-file"
                    class="p-button-rounded p-button-success mx-1"
                  >
                    <font-awesome-icon icon="fa-light fa-square-list" />
                  </Button>
                </div>
                <div v-else>
                  <Button
                    icon="pi pi-file"
                    class="p-button-rounded p-button-success mx-1"
                    :disabled="true"
                  >
                    <font-awesome-icon icon="fa-light fa-square-list" />
                  </Button>
                </div>
              </div>

          </div>
        </template>
      
      
      </Column>


      <!-- DeleteButton -->
      <Column
        :exportable="false"
        style="width: 10rem;"
        v-if="userRole != 'Analyst'"
      >
        <template #body="slotProps">
          <div style="
            display: grid; 
            place-items: center;
          ">
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="openDeleteDialog(slotProps.data)" />
            
          </div>
        </template>
      </Column>
    </DataTable>
    
    <!-- Delete Dialog -->
    <Dialog
      v-model:visible="showDeleteDialog"
      :style="{width: '450px'}"
      header="Delete Company"
      :modal="true"
      @hide="closeDialog"
    >
      <div class="confirmation-content d-flex flex-row">
        <i class="pi pi-exclamation-triangle me-3" style="font-size: 2rem" />
        <span
          >Are you sure you want to delete
          <span class="fw-bold">{{ selectedDeleteCompany.name }}</span>
        </span>
      </div>
    
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeDialog"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          :loading="deleteIsLoading"
          :disabled="deleteIsLoading"
          @click="deleteCompany"
        />
      </template>
    </Dialog>

    <!-- Preview Description  -->
    <Dialog
      v-model:visible= "previewDescriptionDialog"
      :style="{width: '80%'}"
      :modal="true"
    >
      <template #header>
        <h5> Preview <strong>{{selectedRowCompany.name }} Description</strong> </h5> 
      </template>
      <div>
        {{selectedRowCompany.description}}
      </div>
    </Dialog>

    <!-- Preview Description Hover -->
    <div 
      class="modalPreview"
      v-show="previewHoverDescriptionDialog"
    >
      <div class="modalContainer">
          <div v-if="selectedRowCompany">
            <h5>Preview <strong>{{selectedRowCompany.name }} Description  </strong> </h5> 
            {{ selectedRowCompany.description }}
          </div>
          <div v-else>
            loading
          </div>
      </div>
    </div>

    <!-- Preview TopicDescriptionDialog   -->
    <Dialog
      v-model:visible= "previewTopicDescriptionDialog"
      :style="{width: '80%'}"
      :modal="true"
    >
      <template #header>
        <h5> Preview <strong>{{selectedRowCompanyTopicDescriptionDialog.name }} Topic Description</strong> </h5> 
      </template>
      <div>
        {{selectedRowCompanyTopicDescriptionDialog.topicDescription  }}  
      </div>
    </Dialog>
    <!-- preview DocumentContract Hover  -->
    <div 
        class="modalPreview"
        v-show="previewHoverTopicDescriptionDialog"
      >
        <div class="modalContainer">
            <div v-if="selectedRowCompanyTopicDescriptionDialog">
              <h5> Preview <strong>{{selectedRowCompanyTopicDescriptionDialog.name }} Topic Description</strong> </h5> 
              <div>
                {{selectedRowCompanyTopicDescriptionDialog.topicDescription }}
              </div>
            </div>
            <div v-else>
              loading
            </div>
        </div>
    </div>


  </div>
</template>

<script>
import formatToGlobalDate from '@/shared/helpers/date.helper';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import InputSwitch from 'primevue/inputswitch';
import Dropdown  from 'primevue/dropdown'

import { FilterMatchMode, FilterOperator } from 'primevue/api';
// import { useToast } from 'vue-toastification';
// const toast = useToast();

export default {
  name: 'CompaniesList',
  components: { 
    DataTable, 
    Column, 
    Button, 
    InputText,
    Dialog,
    InputSwitch,
    Dropdown,
  },
  data() {
    return {
      columns: null,
      loading: true,
      filters: null,
      // Delete
      showDeleteDialog: false,
      selectedDeleteCompany: null,
      deleteIsLoading: false,

      // show if company is from topic or all companies
      showAllCompanies: false,

      // sortValues
      sortFieldValue: null,
      sortOrderValue: null,
      //paginationCountValues
      firstPagValue :null, 
      rowsPagValue: 25,


      filterSupplyChain:null,

      supplyChain: [
          {
              id: 0,
              name: 'OEM',
          },
          {
              id:1,
              name: 'Tier 1'
          },
          {
              id: 2,
              name: 'Tier 2',
          },
          {
              id:3,
              name: 'Tier 3'
          },
          {
            name:'not Selected'
          }
      ],

      filterCompanyTopicProfile:null,

      companyTopicProfile: [
          {
              id:0,
              name: "Leading Companies",
              description: "Companies with dominant market share - extended profile in study "
          },

          {
              id:1,
              name: "Important Players",
              description: "Companies with dominant market share - extended profile in study "
          },
          {
              id:2,
              name: "Other players",
              description: "Companies that are solid contributors - no profile in study"
          },
          {
            name:'not Selected'
          }
      ],
      previewDescriptionDialog: false ,
      previewHoverDescriptionDialog: false,
      selectedRowCompany : null,

      previewTopicDescriptionDialog:false,
      previewHoverTopicDescriptionDialog: false,
      selectedRowCompanyTopicDescriptionDialog: null,


    };
  },
  async created() {
    this.columns = [
      { field: 'name', header: 'Name' },
      // { field: 'companyType.name', header: 'Type' },
    ];
    this.initFilters();
  },

  async mounted(){
    if(this.selectedTopic){
      await this.getCompanies();
      await this.getCompaniesByTopicId();
    }
    this.setTopicCompaniesSort();
  },
  computed: {
    companies() {
      let companies = JSON.parse(JSON.stringify(this.$store.getters.obtainCompanies));

      if(companies.length>0){
        companies.forEach(c => {
          if(c.companyTopics.length > 0){
            let filteredCompanyTopic =  c.companyTopics.filter(
              topic => topic.topicId === this.selectedTopic.topicId
            )[0]

            if(filteredCompanyTopic){
              let supplyChain = this.supplyChain.find(SC =>SC.id === filteredCompanyTopic.supplyChain ) 
              let companyTopicProfile = this.companyTopicProfile.find(cTp =>cTp.id === filteredCompanyTopic.companyTopicProfile )

              c.topicDescription = (filteredCompanyTopic.description)?filteredCompanyTopic.description:null
              c.supplyChain = (supplyChain)?supplyChain.name: null
              c.companyTopicProfile = (companyTopicProfile)?companyTopicProfile.name:null
            }
            else{
              c.topicDescription = null
              c.supplyChain = null
              c.companyTopicProfile = null
            }
          }
          else{
            c.topicDescription = null
            c.supplyChain = null
            c.companyTopicProfile = null
          }
        });
      }
      
      if(this.filterSupplyChain){
        if(this.filterSupplyChain === 'not Selected'){
          companies = companies.filter(item => !item.supplyChain);
        }
        else{
          companies = companies.filter(item => item.supplyChain === this.filterSupplyChain);
        }
      }

      if(this.filterCompanyTopicProfile){
        if(this.filterCompanyTopicProfile === 'not Selected'){
          companies = companies.filter(item => !item.companyTopicProfile);  
        }
        else{
          companies = companies.filter(item => item.companyTopicProfile === this.filterCompanyTopicProfile);  
        }
      }
      
      return companies
    },
    companiesPerTopic () {
      let companiesPerTopic = JSON.parse(JSON.stringify(this.$store.getters.obtainCompanyPerTopic))
      if(companiesPerTopic.length > 0){
        companiesPerTopic.forEach(cPT => {
          if(cPT.companyTopics.length > 0){
            let filteredCompanyTopic = cPT.companyTopics.filter(
              topic => topic.topicId === this.selectedTopic.topicId
            )[0]

            if(filteredCompanyTopic){
              let supplyChain = this.supplyChain.find(SC =>SC.id === filteredCompanyTopic.supplyChain )
              let companyTopicProfile = this.companyTopicProfile.find(cTp =>cTp.id === filteredCompanyTopic.companyTopicProfile )

              cPT.topicDescription = (filteredCompanyTopic.description)?filteredCompanyTopic.description:null
              cPT.supplyChain = (supplyChain)?supplyChain.name: null
              cPT.companyTopicProfile = (companyTopicProfile)?companyTopicProfile.name:null
            }
            else{
              cPT.topicDescription = null
              cPT.supplyChain = null
              cPT.companyTopicProfile = null  
            }
          }
          else{
            cPT.topicDescription = null
            cPT.supplyChain = null
            cPT.companyTopicProfile = null
          }
        });
      }

      if(this.filterSupplyChain){
        if(this.filterSupplyChain === 'not Selected'){
          companiesPerTopic = companiesPerTopic.filter(item => !item.supplyChain);  
        }
        else{
          companiesPerTopic = companiesPerTopic.filter(item => item.supplyChain === this.filterSupplyChain);  
        }
        
      }

      if(this.filterCompanyTopicProfile){
        if(this.filterCompanyTopicProfile === 'not Selected'){
          companiesPerTopic = companiesPerTopic.filter(item => !item.companyTopicProfile);  
        }
        else{
          companiesPerTopic = companiesPerTopic.filter(item => item.companyTopicProfile === this.filterCompanyTopicProfile);  
        }
      }
      
      return companiesPerTopic;
    },
    selectedTopic() {
      return this.$store.getters.obtainSelectedTopic;
    },
    userRole(){
      return this.$store.getters.obtainCurrentUserRole;

    },
    companySort(){
      return this.$store.getters.obtainTopicCompanySort;
    }
  },
  methods: {
    async getCompanies() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          'obtainCompaniesWithTotalsByTopicId',
          {topicId: this.selectedTopic.topicId, totals:true , byTopic:false}
          // 'obtainFilteredCompanies',
          // {totalDocuments:true , hasPriority: false, totalPrograms:true}
        );
        // toast.success('Companies obtained successfully.');
        this.loading = false;
      } catch (error) {
        // toast.error(error.message);
        this.loading = false;
      }
    },

    setTopicCompaniesSort(){
      //sort
      this.sortFieldValue = this.companySort.sortField;
      this.sortOrderValue = this.companySort.sortOrder;

      // pag
      if(this.companySort.firstPagValue === null){
        this.firstPagValue = 0;
      }
      else{
        this.firstPagValue = this.companySort.firstPagValue; 
      }
      if(this.companySort.rowsPagValue === null){
        this.rowsPagValue = 25
      }
      else{
        this.rowsPagValue = this.companySort.rowsPagValue
      }
      // showAllCompaniesButton
      this.showAllCompanies =  this.companySort.showAllCompanies 
    },

    // TODO: fix this endpoint when backend Finish, 
    async getCompaniesByTopicId(){
      try {
        this.loading = true;
        await this.$store.dispatch(
          // 'obtainCompaniesByTopicId',
          // this.selectedTopic.topicId
          'obtainFilteredCompaniesByTopicIdWithTotalsByTopicId',
          {topicId: this.selectedTopic.topicId, totals:true , byTopic:true}
        );
        this.loading = false;
      } 
      catch (error) {  // COMPANIES NOT FOUND, SET VUEX [] 
        this.$store.dispatch(
          'obtainCompaniesByTopicIdNotFound',
        );
        this.loading = false;
      }
    },
    getLastDocumentUpdated(documents) {
      let result = '';
      if (documents.length < 1 || documents == null) {
        return result;
      }
      if (documents.length == 1) {
        result = documents[0].dateUpdated
          ? documents[0].dateUpdated
          : documents[0].dateAdded;
      } else {
        const dates = documents.map((document) => document.dateUpdated);
        result = dates.reduce((a, b) => {
          return new Date(a) > new Date(b) ? a : b;
        });
      }
      return formatToGlobalDate(result, true);
    },
    formatDate(date) {
      return formatToGlobalDate(date);
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    clearFilter() {
      this.initFilters();
    },
    goToCreateCompany(){
      // this.$router.push(`../companies/company/new`);
      this.$router.push(`./companies/new`);
    },
    open(event){
      this.$router.push(`./companies/edit/${event.data.companyId}`);
    },

    // SHOW IF COMPANY IS FORM TOPIC OR ALL COMPANIES
    showIsCompanyFromTopic(){
      if(this.showAllCompanies){
        return this.companies;
      }
      else{
        return this.companiesPerTopic;
      }
    },

    // DELETE COMPANY METHODS
    // TODO CHECK IF I DELETE COMPANY REMOVE FROM COMPANIES LIST AND COMPANIES PER TOPIC

    openDeleteDialog(company){
      this.selectedDeleteCompany = company;
      this.showDeleteDialog = true; 
    },
    closeDialog(){
      this.selectedDeleteCompany = null;
      this.showDeleteDialog = false;
    },
    async deleteCompany(){
      try {
        this.deleteIsLoading = true; 
        await this.$store.dispatch('removeCompany', this.selectedDeleteCompany.companyId);
        this.deleteIsLoading =false;
        this.closeDialog();
      } catch (error) {
        console.error(error)
        this.deleteIsLoading = false;
      }
    },

    // TODO MAKE ON SORT LOGIC FOR COMAPANIES MODULES TOO 
    onSort(e){
      const companySort = {
        sortField: e.sortField,
        sortOrder: e.sortOrder, 
        firstPagValue: e.first, 
        rowsPagValue: e.rows,
        showAllCompanies: this.showAllCompanies
      }
      this.$store.dispatch('updateTopicCompanySort',companySort)
    },
    changeShowAllCompanies(){
      const companySort = {
        sortField: this.sortFieldValue,
        sortOrder: this.sortOrderValue, 
        firstPagValue: this.firstPagValue, 
        rowsPagValue: this.rowsPagValue,
        showAllCompanies: this.showAllCompanies
      }
      this.$store.dispatch('updateTopicCompanySort',companySort)
    },

    openPreviewDescriptionDialog(description){
      this.previewDescriptionDialog = true; 
      this.selectedRowCompany = description;
    },

    hoverPreviewDescriptionDialogIn(description){
      this.previewHoverDescriptionDialog = true;
      this.selectedRowCompany = description
    },

    hoverPreviewDescriptionDialogOut(){
      this.previewHoverDescriptionDialog= null; 
    },



    openPreviewTopicDescriptionDialig(description){
      this.previewTopicDescriptionDialog = true; 
      this.selectedRowCompanyTopicDescriptionDialog = description;
    },

    hoverPreviewTopicDescriptionDialogIn(description){
      this.previewHoverTopicDescriptionDialog = true; 
      this.selectedRowCompanyTopicDescriptionDialog = description;
    },

    hoverPreviewTopicDescriptionDialogOut(){
      this.previewHoverTopicDescriptionDialog = null;
    },



  },
  watch: {
    async selectedTopic(newValue) {
      if (newValue) {
        await this.getCompanies();
        await this.getCompaniesByTopicId();
      }
    },
    showAllCompanies(newValue){
      const companySort = {
        sortField: this.sortFieldValue,
        sortOrder: this.sortOrderValue, 
        firstPagValue: this.firstPagValue, 
        rowsPagValue: this.rowsPagValue,
        showAllCompanies: newValue
      }
      this.$store.dispatch('updateTopicCompanySort',companySort)
    }
  }
};
</script>

<style scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.keywordSearch{
  /* padding: 0 0 0 2.5rem  !important; */
  padding: 0.2rem 0 0.2rem 2.5rem  !important;
}

.modalPreview{
  position:fixed;
  z-index: 1101;
  top:0; 
  left: 0;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  pointer-events: none;
}
.modalContainer{
  height: 50%;
  width: 80%;
  overflow: hidden;
  background-color: white;
  margin: 0.75rem;
  transform: translate3d(0px, 0px, 0px);
  border-radius: 15px;
  padding: 1rem;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
}
</style>
